/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Flex } from 'theme-ui';

import {
  concessionItemSxProp,
  descriptionSxProp,
  detailSxProp,
  priceSxProp,
  quantitySxProp,
} from './constants';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  Concession,
  ConcessionPricing,
  Modifier,
  ModifierGroup,
} from '../../../@types/modelTypes';
import { JOURNEY_TYPES } from '../../../constants';
import { splitDeliveryItemFromConcessions } from '../../../services/GiftCardHelpers';
import {
  displayPrice,
  getMatchingConcessionDealDetails,
  handleAddRemoveConcessionDynamicBasket,
  handleConcessionQuantityChange,
} from '../../../services/Helpers';
import {
  findModifiedConcessionItemCost,
  handleCartSummaryConcessionQuantityRemoveButton,
} from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectFaBConcessions,
  selectConfig,
  selectCurrencyConfig,
  selectDeals,
  selectSelectedDeliveryWindow,
  selectToken,
  selectTotalFaBItemsSelected,
  selectJourneyTypeConfig,
  selectContent,
  selectSelectedFaBConcessions,
  selectKioskSubStep,
} from '../../../store/Selectors';
import { ReactComponent as DealSvg } from '../../../svgs/deal.svg';
import QuantityButton from '../quantitybutton/QuantityButton';

interface Props {
  hideTax: boolean;
  pathname: string;
}

const CartSummaryFaBConcessions: React.FC<Props> = ({ hideTax, pathname }) => {
  const dispatch = useDispatch();

  const currencyConfig = useSelector(selectCurrencyConfig);
  const concessions = useSelector(selectFaBConcessions);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const config = useSelector(selectConfig);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const deals = useSelector(selectDeals);
  const selectedConcessionCount = useSelector(selectTotalFaBItemsSelected);
  const dataToken = useSelector(selectToken);
  const bookingData = useSelector(selectBookingData);
  const selectedDeliveryWindow = useSelector(selectSelectedDeliveryWindow);
  const kioskSubStep = useSelector(selectKioskSubStep);

  const canEdit =
    (pathname.startsWith('/kiosk/') &&
      (kioskSubStep === 'fab' || kioskSubStep === 'fab-landing')) ||
    pathname.startsWith('/giftcards/select') ||
    pathname.startsWith('/giftmembership/select') ||
    pathname.startsWith('/physicalgiftcards/select') ||
    pathname.startsWith('/concessions/select');

  const handleConcessionChange = async (
    context: QuantitySelectorContext,
    concession: Concession | undefined,
    concessionIndex: number
  ) => {
    const concessionToChange: Concession = JSON.parse(
      JSON.stringify(concession)
    );
    if (config.useDynamicBasket) {
      dispatch(actionCreators.setLoading(true));
      await handleAddRemoveConcessionDynamicBasket(
        context,
        concessionToChange,
        dataToken,
        journeyTypeConfig.type,
        selectedDeliveryWindow,
        bookingData,
        dispatch,
        content
      );
      dispatch(actionCreators.setLoading(false));
    }

    if (context == 'add') {
      handleConcessionQuantityChange(
        'add',
        concessionToChange,
        concessionIndex,
        dispatch,
        true,
        true
      );
    } else {
      handleConcessionQuantityChange(
        'remove',
        concessionToChange,
        concessionIndex,
        dispatch,
        true
      );
    }
  };

  const isAddDisabled = () => {
    if (selectedConcessions == null) {
      return false;
    }

    const physicalGiftCardsMaxQuantiy =
      journeyTypeConfig.usesPhysicalGiftCardsConfigs
        ? config.giftCardsPurchase.maxQuantityOfPhysicalGiftCards
        : 1000;

    const maxQuantity =
      journeyTypeConfig.type === JOURNEY_TYPES.GIFTCARDS_ONLY
        ? config.giftCardsPurchase.maxQuantityOfGiftCards
        : physicalGiftCardsMaxQuantiy;

    return selectedConcessionCount >= maxQuantity;
  };

  const getDescription = (c: Concession) => {
    if (journeyTypeConfig.type === JOURNEY_TYPES.GIFTCARDS_ONLY) {
      return `${displayPrice(c.cost, currencyConfig)} ${
        content.giftCards.summaryGiftCardLabel
      }`;
    } else {
      return c.description;
    }
  };

  const concessionWithModifiers = (
    c: Concession,
    hideTax: boolean,
    ci: number
  ) => {
    const itemPricing: ConcessionPricing = findModifiedConcessionItemCost(c);
    return (
      <>
        <Flex className='concession-item' sx={concessionItemSxProp}>
          <Flex sx={descriptionSxProp}>{getDescription(c)}</Flex>
          <Flex sx={detailSxProp}>
            <Box className='tickets-quantity' sx={quantitySxProp}>
              {c.quantity}
            </Box>
            <Box className='price' sx={priceSxProp}>
              {displayPrice(
                c.quantity *
                  (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
                currencyConfig
              )}
            </Box>
          </Flex>
        </Flex>
        {c.modifiers?.map((m: Modifier, mi: number) => (
          <Box
            key={`c_${c.id}_${ci}_m_${m.id}_${mi}`}
            sx={{ textAlign: 'left' }}
          >
            {'- '}
            {m.description}
            {m.quantity > 1 && <span> x {m.quantity}</span>}
          </Box>
        ))}
      </>
    );
  };

  const concessionWithModifierGroups = (
    concession: Concession,
    hideTax: boolean,
    ci: number
  ) => {
    const itemPricing: ConcessionPricing =
      findModifiedConcessionItemCost(concession);
    const matchedConcessionDeal = getMatchingConcessionDealDetails(
      concession.id,
      deals
    );
    return (
      <>
        <Flex className='concession-item' sx={concessionItemSxProp}>
          <Flex sx={descriptionSxProp}>
            {matchedConcessionDeal && <DealSvg className='deal-icon' />}
            {getDescription(concession)}
          </Flex>
          <Flex sx={detailSxProp}>
            <Box className='concession-item-quantity' sx={quantitySxProp}>
              {concession.quantity}
            </Box>
            {!canEdit && (
              <Box className='price' sx={priceSxProp}>
                {displayPrice(
                  concession.quantity *
                    (hideTax
                      ? itemPricing.costIncTax
                      : itemPricing.costMinusTax),
                  currencyConfig
                )}
              </Box>
            )}
          </Flex>
        </Flex>
        {concession.modifierGroups?.map((mg: ModifierGroup, mgi: number) => (
          <div
            key={`c_${concession.id}_${ci}_mg_${mg.id}_${mgi}`}
            sx={{ fontSize: 'small' }}
          >
            {mg.modifiers
              .filter((x: Modifier) => x.quantity > 0)
              .map((m: Modifier, mi: number) => (
                <Box
                  sx={{ textAlign: 'left' }}
                  key={`c_${concession.id}_${ci}_mg_${mg.id}_${mgi}_m_${m.id}_${mi}`}
                >
                  {'- '}
                  {m.description}
                  {mg.maximumQuantity > 1 && m.quantity > 0 && (
                    <span> x {m.quantity}</span>
                  )}
                </Box>
              ))}
          </div>
        ))}

        {canEdit && (
          <Flex sx={{ alignItems: 'end', justifyContent: 'space-between' }}>
            <Box>
              {concession.modifierGroups.length > 0 ? (
                <Button
                  onClick={() =>
                    dispatch(
                      actionCreators.setFandBItemModalData(
                        concession,
                        'update',
                        true,
                        ci
                      )
                    )
                  }
                  variant='editCartSummary'
                  sx={{ marginTop: 3 }}
                >
                  {content.cartSummary.editButtonText}
                </Button>
              ) : (
                <Flex
                  sx={{ alignItems: 'center', marginTop: 2, minWidth: '120px' }}
                >
                  <QuantityButton
                    context='remove'
                    disabled={concession.quantity <= 0}
                    onClick={() =>
                      handleCartSummaryConcessionQuantityRemoveButton(
                        concession,
                        ci,
                        dispatch,
                        handleConcessionChange
                      )
                    }
                    size='small'
                    isSidebar
                  />
                  <div sx={{ minWidth: '40px', paddingX: 2 }}>
                    {concession.quantity}
                  </div>
                  <QuantityButton
                    context='add'
                    disabled={isAddDisabled()}
                    onClick={() =>
                      handleConcessionChange('add', concession, ci)
                    }
                    size='small'
                    isSidebar
                  />
                </Flex>
              )}
            </Box>
            <Box>
              {displayPrice(
                concession.quantity *
                  (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
                currencyConfig
              )}
            </Box>
          </Flex>
        )}
      </>
    );
  };

  if (
    selectedConcessions === null ||
    selectedConcessions?.list === null ||
    !selectedConcessions?.list?.some((x: Concession) => x.quantity > 0)
  ) {
    return null;
  }

  const { listWithNoDeliveryItem } = splitDeliveryItemFromConcessions(
    concessions,
    selectedConcessions
  );

  return (
    <div className='cart-summary-row'>
      {listWithNoDeliveryItem
        .filter((c: Concession) => c.quantity > 0)
        .map((c: Concession, ci: number) => (
          <div
            data-testid='cs-concessions'
            key={`c_${c.id}_${ci}`}
            sx={{
              borderTop: 'sidebarItemSeparatorColor',
              paddingBottom: 4,
              paddingTop: 4,

              '&:last-child': {
                paddingBottom: 0,
              },
            }}
          >
            {c.modifierGroups
              ? concessionWithModifierGroups(c, hideTax, ci)
              : concessionWithModifiers(c, hideTax, ci)}
          </div>
        ))}
    </div>
  );
};

export default CartSummaryFaBConcessions;
